import TeamsSettingsUserOverviewList from "./list";
import TeamsSettingsUserOverviewShow from "./show";


if (document.body.getAttribute("data-current-path") === "teams/settings/users") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new TeamsSettingsUserOverviewList();
    } else if(action === "show") {
        new TeamsSettingsUserOverviewShow();
    }
}